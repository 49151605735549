<template>
  <Modal closePosition="hidden">
    <div class="root p-6">
      <h2 class="font-h font-semibold text-2xl text-primary">
        Do you want to “Edit Transcript”?
      </h2>

      <div class="mt-2 p-2 text-sm text-primary rounded bg-primary-100">
        💡 Tip: If you are a producer we recommend finishing media edits & then,
        <br/>
        correcting the transcript.
      </div>

      <div class="mt-4 flex items-center justify-between">
        <div class="flex items-center">
          <input type="checkbox" id="checked" v-model="checked">
          <label for="checked" class="text-primary text-xs cursor-pointer flex items-center"> Don’t ask me again</label>
        </div>

        <div>
          <Button variant="secondary" size="small" @click="goToEditTranscript">
            Yes
          </Button>
          <Button size="small" @click="goToEditMedia">
            No
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import store from '@/services/store'
import { setEditMediaDontAsk } from '@/components/common/browserStorageUtility'
import { mapGetters, mapActions } from 'vuex'
import Modal from '@/components/base/dialogs/Modal.vue'
import Button from '@/components/base/buttons/Button.vue'

export default {
  components: {
    Modal,
    Button
  },
  data: function() {
    return {
      checked: false
    }
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    })
  },
  methods: {
    ...mapActions({
      openModal: 'dialogs/openModal',
      closeModal: 'dialogs/closeModal'
    }),
    goToEditMedia () {
      setEditMediaDontAsk(this.checked)
      store.commit('editor/setMode', 'editMedia')
      this.closeModal()
    },
    goToEditTranscript () {
      setEditMediaDontAsk(this.checked)
      store.commit('editor/setMode', 'editTranscript')
      this.closeModal()
    }
  }
}
</script>
<style lang="less" scoped>
.root {
  width: 560px;
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  padding-left: 30px;
}

label::before {
  top: -1px !important;
}

label:after {
  top: 8px !important;
}
</style>
